(function () {
  'use strict';

  angular
    .module('dashboard.results')
    .config(config);

  function config($stateProvider) {
    $stateProvider
      .state('dashboard.results', {
        url: '/results?classroom_id',
        templateUrl: 'dashboard/results/results.tpl.html',
        controller: 'ResultsCtrl',
        controllerAs: 'results',
        roles: ['Learner', 'Teacher']
      });
  }
}());
